import VueRouter from "vue-router";
import Vue from "vue";
Vue.use(VueRouter)

// const Login = () => import('view/login/login.vue')

const Index = () => import('view/index/index.vue')

const Chat = () => import('view/chat/index.vue')
var routes = [
  {
    path:'/',
    component:Index
  },
  {
    path:'/chat',
    component:Chat
  }
]
let router = new VueRouter({
  mode:'history',
  routes
})
export default router
